/* Zaccordion */



.accordion-holder {
    /*Mobile*/
    width: 100%;

    /*Large screen*/
    @include grid-media($large-screen) {
        width: 990px;
        overflow: hidden;
    }

    #homepage-accordion {
        overflow: hidden;

        li {
            /*Mobile*/ 
            background: $white;
            margin-bottom: 2rem;

            @include grid-media($medium-screen) {
                /*@include grid-column(6);*/
                float: left;
                width: 48%;

                /*@include omega(3n);*/
                &:nth-child(2n) {
                    float: right;
                }
                @include omega-reset(2n);
            }

            /*Large screen*/
            @include grid-media($large-screen) {
                margin-bottom: 0;
            }

            h2 {
                /*Mobile*/

                /*Large screen*/
                @include grid-media($large-screen) {
                    margin: 0;
                    z-index: 100;
                    position: absolute;
                    top: 0;
                    left: 0;
                    -webkit-backface-visibility: hidden; /* fixes chrome bug */
                    -webkit-transform: translateX(-100%) rotate(-90deg);
                    -webkit-transform-origin: right top;
                    -moz-transform: translateX(-100%) rotate(-90deg);
                    -moz-transform-origin: right top;
                    -o-transform: translateX(-100%) rotate(-90deg);
                    -o-transform-origin: right top;
                    transform: translateX(-100%) rotate(-90deg);
                    transform-origin: right top;

                    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

                    width: 370px;
                    height: 45px;
                    line-height: 45px;
                    line-height: 25px;
                }

                span {
                    /*Mobile*/
                    display: block;
                    height: 100%;
                    line-height: 41px;
                    height: 41px;
                    margin-top: 5px;

                    padding-left: 3%;
                    padding-right: 3%;
                    text-align: left;


                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;

                    color: #fff;
                    font-weight: bold;
                    font-size: 0.95em;
                    font-family: verdana, arial, sans-serif;

                    /*Large screen*/
                    @include grid-media($large-screen) {
                        margin-top: 0;
                    }
                }
            }

            .slide-content {
                /*Mobile*/

                /*Large screen*/
                @include grid-media($large-screen) {
                    margin-left: 45px;
                }

                .slide-picture {
                    /*Mobile*/

                    /*Large screen*/
                    @include grid-media($large-screen) {
                        position: absolute;
                        width: 675px;
                        overflow: hidden;
                    }

                    img {
                        /*Mobile*/
                        width: 100%;
                        height: auto;

                        /*Large screen*/
                        @include grid-media($large-screen) {
                            height: 370px;
                            width: auto;
                        }
                    }
                }

                .slide-text {
                    /*Mobile*/

                    /*Large screen*/
                    @include grid-media($large-screen) {
                        position: relative;
                        width: 180px;
                        left: 0;
                        top: 140px;
                        padding: 13px !important;
                    }

                    .background {
                        /*Mobile*/

                        /*Large screen*/
                        @include grid-media($large-screen) {
                            position: absolute;
                            width: 100%; 
                            height: 100%;
                            top: 0;
                            left: 0;
                            filter:alpha(opacity=80); /* IE */
                            -moz-opacity:0.8; /* Mozilla */
                            opacity: 0.8; /* CSS3 */
                            z-index: 1 !important;
                        }
                    }

                    .text {
                        position: relative;
                        z-index: 10;
                        font-size: 1em;
                        line-height: 1.2em;
                        color: #FBF4A4;
                    }
                }

                .read-more {
                    /*Mobile*/

                    /*Large screen*/
                    @include grid-media($large-screen) {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 50px;
                        pointer-events: none;
                    }

                    .read-more-background {
                        /*Mobile*/

                        /*Large screen*/
                        @include grid-media($large-screen) {
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            height: 50px;
                            left: 0;
                            background: #F9F2AB;
                            filter:alpha(opacity=80); /* IE */
                            -moz-opacity:0.8; /* Mozilla */
                            opacity: 0.8; /* CSS3 */
                            z-index: 1 !important;
                        }

                        .endorsement-text {
                            /*Mobile*/
                            margin-bottom: 0.5rem;
                            /*Large screen*/
                            @include grid-media($large-screen) {
                                width: 460px;
                                margin-left: 125px !important;
                                margin-top: 2px !important;
                                height: 100%;
                                overflow: hidden;
                                color: #556647;
                                font-size: 0.9em;
                                line-height: 1.05em;
                                margin-bottom: 0;
                            }

                            .quote {
                                font-style: italic;

                            }

                            .author {
                                font-weight: bold;

                            }

                            .author_workplace {
                                font-weight: bold;

                            }
                        }
                    }

                    a {
                        /*Mobile*/
                        width: 100%;
                        height: 30px;
                        line-height: 30px;
                        font-size: 1em;
                        color: #fff;
                        display: inline-block;
                        text-align: center;
                        -webkit-border-top-left-radius: 5px;
                        -webkit-border-top-right-radius: 5px;
                        -moz-border-radius-topleft: 5px;
                        -moz-border-radius-topright: 5px;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;

                        /*Large screen*/
                        @include grid-media($large-screen) {
                            width: 95px;
                            position: absolute;
                            bottom: 0;
                            left: 15px;
                            z-index: 10;
                        }
                    }
                }
            }
        }
    }
}

.slide-text, .slide-text > .background, .slide-text > .text { display: block; }

#homepage-accordion .read-more-background {

}

#homepage-accordion .read-more {

}

#homepage-accordion .read-more a {

}