.woocommerce-message, .woocommerce-info {
    border-top-color: $toastyfresh-green !important;

    &:before {
        color: $toastyfresh-green !important;
    }
}

.woocommerce-error {
    border-top-color: $toastyproducts-red !important;

    &:before {
        color: $toastyproducts-red !important;
    }
}

