.contact-article {
    /*Mobile*/

    /*Large screen*/
    @include grid-media($large-screen) {
        display: inline-block;
        vertical-align: top;
        width: 765px;
        // margin-left: 20px;
        // padding-left: 80px;  
        // border: 1px solid;      
    }

    h1 {
        /*Mobile*/
        color: #939598;
        font-size: 1rem;
        margin-top: 1rem;

        /*Medium screen*/
        @include grid-media($medium-screen) {
            font-size: 1.3rem;
        }

        /*Large screen*/
        @include grid-media($large-screen) {
            font-size: 1.7rem;
            padding-left: 100px;
            margin-top: 0;
        }
    }

    ul {
        padding-bottom: 2rem;

        li {
            /*Mobile*/
            padding-bottom: 1rem;

            /*Medium screen*/
            @include grid-media($medium-screen) {
                height: 70px;
                margin-bottom: 15px;
            }

            i {
                font-size: 4rem;
                float: left;
                text-align: center;
            }

            p {
                /*Mobile*/ 
                padding: 0;
                color: #939598;
                font-size: 1rem;

                /*Medium screen*/
                @include grid-media($medium-screen) {
                    margin-left: 100px;
                    line-height: 35px;
                    font-size: 1.5rem;
                }

                /*Large screen*/
                @include grid-media($large-screen) {
                    font-size: 2.2rem;
                }
            }

            &.phone-no {
                i {
                    /*Mobile*/
                    display: none;
                    color: $toastyfresh-purple;

                    /*Medium screen*/
                    @include grid-media($medium-screen) {
                        display: inline-block;
                        margin-left: 0.5rem;
                    }
                }

                p {
                    padding-top: 10px;

                    i {
                        color: red; 
                    }
                }

                span.number {
                    /*Mobile*/ 
                    color: $toastyfresh-purple;
                    display: block;
                    margin-top: 0.3rem;

                    /*Medium screen*/
                    @include grid-media($medium-screen) {
                        display: inline-block;
                        margin-top: 0;
                    }
                }
            }

            &.email-address {
                i {
                    /*Mobile*/
                    display: none;
                    color: $toastyfresh-green;

                    /*Medium screen*/
                    @include grid-media($medium-screen) {
                        display: inline-block;
                        transform: rotate(-30deg);
                        margin-left: 7px;
                    }
                }


                a.email-link {
                    /*Mobile*/
                    color: $toastyfresh-green;
                    display: block;
                    margin-top: 0.3rem;

                    /*Medium screen*/
                    @include grid-media($medium-screen) {
                        margin-top: 0;
                    }
                }

            }
        }
    }

    .fill-in-form {
        /*Mobile*/
        font-size: 1.5rem;
        padding: 0;
        color: #939598;
        line-height: 35px;
        padding-bottom: 0.5rem;

        /*Large screen*/
        @include grid-media($large-screen) {
            font-size: 2.2rem;
        }
    }
}

.page-template-contact {
    .wpcf7-submit {
        font-size: 0.9rem;
    }
}
