ul.products {

    li.product-category {
        /*Mobile*/
        /*width: 100% !important;*/

        /*Large screen*/
        @include grid-media($large-screen) {
/*            width: 25% !important;
            position: relative;
            display: inline-block; 
            margin: 0 !important;
            
            @include omega-reset(5);*/
        }

        figure {
            display: block;

            img {
                border-radius: $base-border-radius * 5;
                margin: 0 !important;
            }

            figcaption {
                /*Mobile*/

                /*Large screen*/
                @include grid-media($large-screen) {
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #fff;
                    opacity: 0;
                    padding: 2rem;
                    -webkit-transition: opacity 0.2s ease-in;
                    -o-transition: opacity 0.2s ease-in;
                    transition: opacity 0.2s ease-in;
                    pointer-events: none;
                    border-radius: $base-border-radius * 5;                    
                }
            }

        }

        &:hover {

            figure {
                figcaption {
                    opacity: 0.8;
                }
            }

        }
    }

}

