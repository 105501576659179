.js-scroll-animate {
    opacity: 0;
    transform: scale(0.5, 0.5);
    transition: opacity $base-duration $base-timing, transform $base-duration $base-timing;
    border-bottom: $base-border;
    padding-bottom: 3rem;

    &.active {
        opacity: 1;
        transform: scale(1);
    }

    @include grid-media($medium-screen) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0;
        padding-bottom: 0;

        article.products-main-content, aside.side-pic {
            padding-top: 0;
            margin-top: 0;
        }

        &:first-child {
            padding-top: 2rem;
        }

        &:nth-child(even) {
            flex-direction: row-reverse;
            article.products-main-content {
                padding-right: 0;
                padding-left: 2rem;
            }
        }
    }

    div.secondary-text-block {
        padding-top: 2rem;
    }

    &:last-child {
        border-bottom: 0;
    }
}
