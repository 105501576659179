.product-category {
    /*width: 30.75% !important;*/
/*    float: left;
    margin: 0 3.8% 2.992em 0;
    margin-left: 0px;
    padding: 0;
    position: relative;
    width: 22.05%;
    margin-left: 0;*/

    h2 {
        padding: .5em 0;
        margin: 0;
        font-size: 1em;
    }
}