#{$all-buttons}, .button {
    appearance: none;
    background-color: $button-color !important;
    border: 0;
    border-radius: $base-border-radius !important;
    color: $white !important;
    cursor: pointer;
    display: inline-block;
    font-family: $base-font-family;
    font-size: $base-font-size;
    -webkit-font-smoothing: antialiased;
    font-weight: 600;
    line-height: 1.2;
    /*padding: $small-spacing $base-spacing;*/
    text-decoration: none;
    /*transition: background-color $base-duration $base-timing;*/
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    /*width: 100%;*/
    text-align: center;
    padding: .618em 1em;

    &:hover,
        &:focus {
        /*background-color: shade($action-color, 20%);*/
        color: #fff;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;

        &:hover {
            /*background-color: $action-color;*/
        }
    }

    // Medium screen
    /*    @include grid-media($medium-screen) {
            width: auto;
        }*/
}







.toasty-buttons {
    margin-top: 22px;

    ul {

        li {
            /*Mobile*/
            display: block;
            margin-bottom: 0.5rem;

            /*Large screen*/
            @include grid-media($large-screen) {
                display: inline-block;
                margin-bottom: 0;
            }

            a {
                /*Mobile*/
                display: block;

                /*Large screen*/
                @include grid-media($large-screen) {
                    display: inline-block;
                }
            }
        }
    }
}
