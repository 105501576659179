.customer-order-number {
    /*Mobile*/
    width: 100%;

    /*Medium screen*/
    @include grid-media($large-screen){
        width: 48%;
    }
}

