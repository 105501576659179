h1.quotation-header {
    font-size: 2.2em;
    font-weight: bold;
    margin-top: 20px;
}

h2.quotation-header {
    /*Mobile*/
    color: #F59133;
    padding-bottom: 20px;

    /*Large screen*/
    @include grid-media($large-screen) {
        margin-top: 10px;
        font-size: 1.8em;
        font-weight: normal;
        padding-left: 50px;

    }
}

#quotation-form {
    /*Mobile*/
    width: 100%;

    /*Large screen*/
    @include grid-media($large-screen) {
        width: auto;
        float: left;
        clear: both;
        overflow: auto;
        overflow: hidden;
    }

    .product-orders {
        /*Mobile*/
        width: 100%;

        /*Large screen*/
        @include grid-media($large-screen) {
            background: #f9ede1; /* Old browsers */
            background: -moz-linear-gradient(top,  #f9ede1 0%, #f8b87b 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f9ede1), color-stop(100%,#f8b87b)); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top,  #f9ede1 0%,#f8b87b 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(top,  #f9ede1 0%,#f8b87b 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(top,  #f9ede1 0%,#f8b87b 100%); /* IE10+ */
            background: linear-gradient(to bottom,  #f9ede1 0%,#f8b87b 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9ede1', endColorstr='#f8b87b',GradientType=0 ); /* IE6-9 */


            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            border-radius: 50px;

            padding-top: 15px;
            padding-right: 105px;
            padding-bottom: 40px;
            padding-left: 105px;

            overflow: auto;
            overflow: hidden;

            width: 790px;
        }


        label {
            /*Mobile*/
            font-size: 1rem !important;
            display: inline-block;
            width: auto;
            padding: 0.5rem 0;

            /*Large screen*/
            @include grid-media($large-screen) {
                /*clear: both;*/
                width: 550px !important;
                text-align: left !important;
                font-size: 1.4em !important;
            }
        }

        .total-boxes {
            /*Mobile*/
            float: right !important;
            width: 187px !important;

            /*Large screen*/
            @include grid-media($large-screen) {
                float: left !important;
                clear: both;
                width: 100% !important;
                margin-top: 30px;
            }

            label {
                /*Mobile*/

                /*Large screen*/
                @include grid-media($large-screen) {
                    text-align: right !important;
                    color: #0E8B85;
                    font-size: 2em !important;
                    font-weight: normal !important;
                    width: 500px;
                    float: left;
                }
            }

            input[type=text] {
                /*Mobile*/
                width: 187px !important;

                /*Large screen*/
                @include grid-media($large-screen) {
                    width: 158px !important;
                }
            }
        }
    }

    .half-width, .full-width {
        /*Mobile*/

        /*Large screen*/
        @include grid-media($large-screen) {
            float: left;
            margin-top: 20px;
        }
    }

    .half-width {
        /*Mobile*/

        /*Large screen*/
        @include grid-media($large-screen) {
            width: 50%;
        }

        input[type=text] {
            /*Mobile*/
            margin-bottom: 0.5rem;

            /*Large screen*/
            @include grid-media($large-screen) {
                margin-bottom: 0;
                width: 48%;
                padding-left: 2%;
                padding-right: 2%;
            }
        }
    }

    .full-width {
        width: 100%;

        input[type=text] {
            /*Mobile*/
            margin-bottom: 0.5rem;

            /*Large screen*/
            @include grid-media($large-screen) {
                margin-bottom: 0;
                width: 74%;
                padding-left: 1%;
                padding-right: 1%;
            }
        }
    }

    label {
        /*Mobile*/
        width: 100%;

        /*Large screen*/
        @include grid-media($large-screen) {
            float: left;
            text-align: right;
            width: 200px;
            font-size: 1.2em;
            font-weight: bold;
        }
    }

    input[type=text] {
        /*Mobile*/
        color: #000;
        border: 1px solid #666;

        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;

        -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.6);
        -moz-box-shadow:    0px 0px 10px 0px rgba(0, 0, 0, 0.6);
        box-shadow:         0px 0px 10px 0px rgba(0, 0, 0, 0.6);

        height: 22px;

        /*Large screen*/
        @include grid-media($large-screen) {
            float: right;
            width: 50%;

            margin-right: 10px;
        }
    }    

    .product-orders {
        float: left;
        clear: both;
        margin-top: 30px;

        h2, h3 {
            float: left;
            clear: both;
            color: #0E8B85;
            margin-top: 10px;
        }

        h2 {

            &.heading {
                /*Mobile*/
                display: none;

                /*Large screen*/
                @include grid-media($large-screen) {
                    width: auto;
                    display: inline-block;
                    clear: none !important;
                    font-weight: normal;
                    font-size: 2.2em;
                    padding-bottom: 30px;
                }

                &.right {
                    float: right !important;
                }
            }
        }

        .product-container {
            /*Mobile*/
            float: left;
            clear: both;
            padding-bottom: 2px;
            width: 100%;

            /*Large screen*/
            @include grid-media($large-screen) {
                height: 33px;
                line-height: 33px;
            }

            label:not(.hidden-label) {
                /*Mobile*/
                margin-bottom: 0.3rem;
                width: 90%;

                /*Large screen*/
                @include grid-media($large-screen) {
                    margin-bottom: 0;
                }
            }

            .hidden-label {
                /*Mobile*/
                height: 33px;
                line-height: 33px;
                width: auto !important;
                display: inline-block;
                font-size: 0.9rem !important;
                margin-bottom: 1rem;

                /*Large screen*/
                @include grid-media($large-screen) {
                    display: none;
                }
            }
        }

        h3 {
            /*Mobile*/
            margin-bottom: 0.2rem;
            font-weight: bold;
            font-size: 1.4em;

            /*Large screen*/
            @include grid-media($large-screen) {
                padding-bottom: 5px;
                margin-bottom: 0;
            }

            &.product-category {
                /*Mobile*/
                margin-bottom: 0.5rem;
                margin-top: 2rem;

                /*Large screen*/
                @include grid-media($large-screen) {
                    margin-bottom: 0;
                }
            }
        }

        input[type=number], input[type=text] {
            /*Mobile*/
            display: inline-block;
            background: #F59133;
            width: auto !important;
            border: none !important;
            -webkit-border-radius: 0 !important;
            -moz-border-radius: 0 !important;
            border-radius: 0 !important;

            -webkit-box-shadow: none !important;
            -moz-box-shadow:    none !important;
            box-shadow:         none !important;
            height: 20px !important;
            padding-left: 1em;
            float: right;

            /*Large screen*/
            @include grid-media($large-screen) {
                margin-right: 30px !important;
            }
        }

    } 
    /*Product Orders End*/

    .checkbox-section{
        width: 100%;
        padding-left: 0;


        div {
            float: left;
            clear: both;
            margin-top: 10px;
        }

        input {
            float: left;
            background: #F8B679;
            border: none;
        }

        label {
            /*Mobile*/
            color: #0E8B85;
            font-weight: normal;
            clear: both;
            margin-left: 0.3rem;

            /*Large screen*/
            @include grid-media($large-screen) {
                width: auto !important;
                font-size: 2em !important;
                font-weight: normal !important;
                margin-left: 10px;
                clear: none;
            }
        }
    }

    .submit-button-holder {
        float: left;
        clear: both;
        width: 100%;
        text-align: center;
        padding-bottom: 100px;
    }

    input[type=submit] {
        clear: both;
        margin: auto;
        background: transparent url('../images/button.jpg') top center no-repeat;
        color: $toastyproducts-green !important;
        width: 244px;
        height: 63px;
        border: none;
        cursor: pointer;
        font-size: 2rem;
        line-height: 1.8rem;
    }

    .min-quantity {
        /*Mobile*/
        color: #0E8B85;
        float: left;
        clear: both;

        /*Large screen*/
        @include grid-media($large-screen) {
            font-size: 2em;
        }
    }

    .checkbox-section {
        float: left;
        clear: both;
    }

    .min-quantity, .checkbox-section {
        /*Mobile*/

        /*Large screen*/
        @include grid-media($large-screen) {
            padding-left: 100px;
        }
    }

    /*    .quotation-form-checkbox {
             Mobile 
            float: right;
            margin-top: 0.7rem;
            
            
             Large screen 
            @include grid-media($large-screen) {
                float: none;
                margin-left: 10rem;
                margin-top: 1.1rem;
            }
        }*/

    label.checkbox-container {
        $checkbox-width: 1.5rem;
        display: inline-block;
        width: 2rem !important;
        position: relative;
        /*margin-bottom: 12px;*/
        cursor: pointer;
        font-size: 2rem;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        /*background: yellow;*/
        float: right;
        top: 5px;

        /* Hide the browser's default radio button */
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            /* When the radio button is checked, add a blue background */
            &:checked  {
                ~.checkmark {
                    background-color: #2196F3;

                    /* Show the indicator (dot/circle) when checked */
                    &:after {
                        display: block !important;
                    }
                }
            }
        }

        /* Create a custom radio button */
        .checkmark {
            position: absolute;
            top: 0;
            height: $checkbox-width;
            width: $checkbox-width;
            background-color: #fff !important;
            border: 2px solid $toastyproducts-green;
            font-size: 1.3rem;
            border-radius: 5px;


            /* Create the indicator (the dot/circle - hidden when not checked) */
            &:after {
                content: "\2713";
                color: $toastyproducts-green !important;
                text-align: center;
                display: none;
                position: absolute;
                top: 2px;
                left: 3px;

                transform: rotate(20deg);
                font-weight: bold;

                /* Large screen */
                @include grid-media ($large-screen) {
                    top: -5px;
                    left: 4px;
                }
            }
        }

        /* On mouse-over, add a grey background color */
        &:hover {
            input ~ .checkmark {
                box-shadow: 0 0 4px 0px $toastyproducts-green;
                /* in order: x offset, y offset, blur size, spread size, color */
            } 
        }
    }

} 
/*Quotation Form End*/











.fillings-gluten-free-logo {
    position: absolute;
    top: 5px;
    right: 3px;
}

.sent-message {
    margin-top: 20px;
}

.error-message, .error-message-total-boxes, .products-quote-error-message {
    /*Mobile*/
    float: left;
    clear: both;
    width: 100%;
    color: #F00;
    text-align: center;
    font-size: 1.5em;

    /*Large screen*/
    @include grid-media($large-screen) {
        font-size: 2em;
    }
}

.products-quote-error-message {
    margin-bottom: 1rem;
}