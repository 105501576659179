.mobile-nav-container {
    width: 90%;
    overflow: hidden;
    // border: 5px solid purple;
    position: fixed;
    left: 5%;
    top: $mobile-header-height;

    nav.mobile-nav {
        display: none;
        width: 200%;
        transform: translateX(0);
        transition: transform ease-in-out 0.5s;

        &.show {
            display: block;

            .top-nav {
                display: block;
            }
        }

        ul {
            width: 50%;
            float: left;

            li {
                height: $mobile-nav-line-height;
                line-height: $mobile-nav-line-height;

                a {
                    padding-left: 1rem;
                }
            }
        }

        .product-nav-container {
            width: 50%;
            display: none;
            height: 0;

            ul {
                width: 100%;

                li {
                    display: block;

                    a {
                        display: block;
                        background: #D97249;
                        color: $white;
                    }
                }
            }

            #products-back-link {
                height: $mobile-nav-line-height;
                line-height: $mobile-nav-line-height;
                padding-left: 1rem;
                display: block;
                width: 100%;

                font-family: 'Istok Web';
                font-weight: bold;
                background: #939598;
                color: $white;
                
                &:hover {
                    background-color: shade(#939598, 20%);
                }
            }
        }

        &.shift-left {
            transform: translateX(-50%) !important;

            .product-nav-container {
                display: inline-block;
                height: auto;
            }
        }
    }
}

a.sidedrawer-toggle {
    /*Mobile*/
    display: inline-block;
    font-size: 2.3rem;
    color: $toastyfresh-green;
    margin: 0.5rem 0;

    /*Large screen*/
    @include grid-media($large-screen) {
        display: none;
    }

    &.active {
        color: #D97249;
    }
}

#products-menu-link {
    width: 50%;
    display: block;
    float: left;
    clear: both;
    height: $mobile-nav-line-height;
    line-height: $mobile-nav-line-height;
    padding-left: 1rem;

    color: $white;
    background: $toastyfresh-green;

    &:hover {
        background-color: shade($toastyfresh-green, 20%);
    }
}

