@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: 0; }
  &:nth-child(#{$nth}+1) { clear: left }
}

/* Base */
/*@import "base/variables";*/
@import "base/reset";
/*@import "base/typography";*/
/*@import "base/forms";*/


/*Bourbon*/ 
@import "../node_modules/bourbon/app/assets/stylesheets/bourbon";

/*Neat*/ 
@import "../node_modules/bourbon-neat/core/neat";

/*Bitters*/ 
@import "base/base";

/* Components */
@import "components/extra-products";
@import "components/mobile-nav";
@import "components/homepage-accordion";
@import "components/buttons";
@import "components/infographic";
@import "components/toastyproducts-express-products";
@import "components/quotation-form";
@import "components/woocommerce-product-order-form";
@import "components/woocommerce-customer-order-number-section";

/* Layout */
@import "layout/base";
@import "layout/header";
@import "layout/footer";

/* Pages */
@import "pages/home";
@import "pages/product";
@import "pages/about";
@import "pages/contact";
@import "pages/go-green";
@import "pages/paninis";
@import "pages/toastyproducts-express";

/* Fonts */
@import "../node_modules/font-awesome/scss/font-awesome";

/* Woocommerce Overrides */
@import "woocommerce-overrides/cart";
@import "woocommerce-overrides/frozen-products";
@import "woocommerce-overrides/messages";
@import "woocommerce-overrides/forms";
@import "woocommerce-overrides/homepage-pods";

/*Contact Form 7 Overrides*/
@import "contact-form-7-overrides/contact-form-7-overrides";


table {
    /*table-layout: auto;*/
}


/*
Typical architecture :

|– base/ 
|   |– _reset.scss       # Reset/normalize 
|   |– _typography.scss  # Typography rules 
|   ...                  # Etc… 
| 
|– components/ 
|   |– _buttons.scss     # Buttons 
|   |– _carousel.scss    # Carousel 
|   |– _cover.scss       # Cover 
|   |– _dropdown.scss    # Dropdown 
|   |– _navigation.scss  # Navigation 
|   ...                  # Etc… 
| 
|– helpers/ 
|   |– _variables.scss   # Sass Variables 
|   |– _functions.scss   # Sass Functions 
|   |– _mixins.scss      # Sass Mixins 
|   |– _helpers.scss     # Class & placeholders helpers 
|   ...                  # Etc… 
| 
|– layout/ 
|   |– _grid.scss        # Grid system 
|   |– _header.scss      # Header 
|   |– _footer.scss      # Footer 
|   |– _sidebar.scss     # Sidebar 
|   |– _forms.scss       # Forms 
|   ...                  # Etc… 
| 
|– pages/ 
|   |– _home.scss        # Home specific styles 
|   |– _contact.scss     # Contact specific styles 
|   ...                  # Etc… 
| 
|– themes/ 
|   |– _theme.scss       # Default theme 
|   |– _admin.scss       # Admin theme 
|   ...                  # Etc… 
| 
|– vendors/ 
|   |– _bootstrap.scss   # Bootstrap 
|   |– _jquery-ui.scss   # jQuery UI 
|   ...                  # Etc… 
| 
| 
`– main.scss             # primary Sass file 



*/