body {
    div.extra-products {
        border-top: $base-border;
        margin-top: 2rem;
    }

    a.more-systems {
        margin-top: 2rem;
        padding-top: 1.2rem !important;
        padding-bottom: 1.2rem !important;
        background: $toastyfresh-purple !important;
    }

    article.products-main-content {
        /* Mobile */
        display: block;
        padding-top: 40px;

        /* Medium screen */
        @include grid-media($medium-screen) {
            display: inline-block !important;
            width: 70%;
            // padding-top: 0;

            p:last-child {
                padding-bottom: 0;
            }
            
            &.large-screen-only {
                display: none !important;
            }
        }

        /* Large screen */
        @include grid-media($large-screen) {
            display: inline-block !important;
            width: 707px;
            vertical-align: top; 
            padding-right: 2rem;
            
            &.large-screen-only {
                display: inline-block !important;
            }
        }
    }

    aside.side-pic {
        /* Mobile */
        display: block;
        width: 100%;
        margin-top: 1rem;

        /* Medium screen */
        @include grid-media($medium-screen) {
            display: inline-block;
            width: 29%;
        }

        /* Large screen */
        @include grid-media($large-screen) {
            width: 277px;
        }

        img {
            /* Mobile */
            width: 100%;

            /* Large screen */
            @include grid-media($large-screen) {
                margin-top: 2rem;
            }
        }
    }
    
    &.page-template-front-page {
        aside.side-pic {
            .order-now-section {
                margin-top: 0;
                img {
                    margin-top: 0;
                }
            }
        }
    }
}

