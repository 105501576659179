.page-template-paninis {
    .banner-section.paninis {
        .image {
            width: 100%;
        }
    }

    ul:not(.menu) {
        list-style: disc inside;
        width: 100%;
        padding-bottom: 1em;

        li {
            padding-top: 1em;
            font-size: 0.9rem;
        }
    }
}
