body {
    color: $base-font-color;
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $heading-font-family;
    /*font-size: modular-scale(1);*/
    /*line-height: $heading-line-height;*/
    /*margin: 0 0 $small-spacing;*/
}

h1 {
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: bold;
    color: $toastyfresh-green;
    padding-bottom: 1em;
}

.woocommerce-page h1, .page-template-frozen_products h1, .page-template-frozen_pizzas h1 {
    color: $toastyfresh-purple;
}

h2 {
    font-weight: bold;
    font-size: 1.5em;
    line-height: 1.5rem;
    color: $toastyfresh-purple;
}

p {
    padding: 0.5em 0;
    font-size: 0.9rem;
}

a {
    color: $link-color;
    text-decoration: none;

    &.small-quantity-orders, &.large-quantity-orders {
        color: $woocommerce-link-color;

        &:hover {
            color: shade($woocommerce-link-color, 20%);
        }
    }

    &:hover {
        color: shade($link-color, 20%);
    }
}

.woocommerce-page a {
    color: $woocommerce-link-color;

    &:hover {
        color: shade($woocommerce-link-color, 20%);   
    }
}

strong {
    font-weight: bold;
}