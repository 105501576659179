html,
body {
    width: 100%;
    height: 100%;
}

.container {
    /*Mobile*/
    width: 90%;
    margin: auto;

    /*Large screen*/
    @include grid-media($large-screen) {
        width: 990px;
        margin: auto;
        /*min-height: 90vh;*/
    }
}

.content {
    padding-top: 15px;
    padding-bottom: 35px;
    position: relative;
    padding-top: calc(#{$mobile-header-height} + 1.5rem);

    @include grid-media($large-screen) {
        padding-top: calc(#{$header-height} + 1.5rem);
    }
}

.hide {
    /*
    display: none;
    */
    float: left;
    clear: both;
    visibility: hidden;
}

.float-clear {
    clear: both;
}

.company-info {
    /*Mobile*/

    /*Large screen*/
    @include grid-media($large-screen) {
        float: left;
        width: 48%;
        padding-bottom: 1em;
    }
}

img {
    max-width: 100%;
    height: auto;
}

.large-screen-only {
    /*Mobile*/
    display: none;

    /*Large screen*/
    @include grid-media($large-screen) {
        display: block;
    }
}

.mobile-and-medium-screen-only {
    /*Mobile*/
    display: block;

    /*Large screen*/
    @include grid-media($large-screen) {
        display: none;
    }
}

.mobile-tablet-content-logo {
    margin-bottom: 1rem;
    img.header-logo {
        display: block;
        max-width: 300px;
        margin: auto;
    }

    p.phone {
        /* Mobile */
        color: $toastyfresh-green;
        font-weight: bold;
        text-align: center;
    }

    img.union-jack {
        height: 2.2rem;
        vertical-align: top;
    }

    .tagline {
        font-size: 0.9rem;
        vertical-align: middle;
        line-height: 2.2rem;
        font-weight: bold;
        margin-left: 1rem;
    }

    @include grid-media($large-screen) {
        display: none;
    }
}
