.columns {
    display: inline-block;
    margin-top: 30px;
    width: auto;

    .column {
        /*Mobile*/
        margin-bottom: 2rem;

        /*Large screen*/
        @include grid-media($large-screen) {
            width: 284px;
            display: inline-block;
            vertical-align: top;
            margin-left: 65px;
            margin-right: -0.25em;
            margin-bottom: 0;
        }


        h2 {
            font-size: 1em;
            padding-top: 1em;
        }

        img {
            
        }

        ul {
            list-style: disc inside;
            width: 100%;
            padding-bottom: 1em;

            li {
                padding-top: 1em;
                font-size: 0.9rem;
            }
        }

        .dimensions {
            color: $white;
            padding: 10px;
            padding-top: 0;
            margin-top: 15px;
        }

        &.first {
            margin-left: 0;
        }
    }
}

.banner-section {
    /*Mobile*/

    /*Large screen*/
    @include grid-media($large-screen) {
        height: 198px;
        overflow: hidden;
    }

    .image {
        /*Mobile*/
        position: relative;


        /*Large screen*/
        @include grid-media($large-screen) {
            width: 704px;
            height: 198px;
            float: left;
        }

        img {
            width: 100%;
            position: relative;
        }

        span.gluten-free-logo {
            position: absolute;
            bottom: 0;
            left: 0;
            display: inline-block;
            width: 95px;
            height: 30px;
            font-size: 0.9em;
            padding: 2px 20px 0 20px;
            line-height: 1.2em;
            font-weight: bold;
            text-align: center;

            -webkit-border-top-left-radius: 5px;
            -webkit-border-top-right-radius: 5px;
            -moz-border-radius-topleft: 5px;
            -moz-border-radius-topright: 5px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    }

    .text {
        /*Mobile*/
        padding: 1rem;

        /*Large screen*/
        @include grid-media($large-screen) {
            float: right;
            color: #fff;
            width: 278px;
            height: 100%;
            padding: 10px 20px;
            line-height: 1.3em;
        }
    }
}

.one-column-filler-pic {
    /*Mobile*/

    /*Large screen*/
    @include grid-media($large-screen) {
        display: inline-block;
        width: 248px;
        vertical-align: top;
        margin-left: 65px;
        margin-right: -0.25em;
        overflow: hidden;
        margin-top: 30px;
    }
}

.two-column-filler-pic {
    /*Mobile*/

    /*Large screen*/
    @include grid-media($large-screen) {
        display: inline-block;
        width: 640px;
        vertical-align: top;
        margin-left: 65px;
        margin-right: -0.25em;
        margin-top: 30px;        
    }
}



