// Typography
//$base-font-family: $helvetica;
/*$heading-font-family: $base-font-family;*/

// Font Sizes
$base-font-size: 12px;

// Line height
$base-line-height: 1.6em;
$mobile-nav-line-height: 2.5rem;

// Colours
$white: #FFFFFF;
$grey: #5F6062;
// Toasty Products Colours
$toastyproducts-green: #008A78;
$toastyproducts-red: #ED1D2E;
// Toasty Fresh Colours
$toastyfresh-purple: #6F298C;
$toastyfresh-green: #7AC141;
$toastyfresh-tertiary: #D97249;

// Button background colour
$button-color: $toastyfresh-green;
$woocommerce-button-color: $toastyfresh-purple;
$woocommerce-action-button-color: $toastyfresh-green;

// Font colour
$base-font-color: $grey;

// Border Radius
$base-border-radius: 5px;

// Typography
$base-font-family: verdana, sans serif; 
$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: 12px;

// Line height
/*$base-line-height: 1.6em;*/
/*$heading-line-height: 1.2;*/

// Other Sizes
$base-border-radius: 5px;
/*$base-spacing: $base-line-height * 1em;*/
/*$small-spacing: $base-spacing / 2;*/
/*$base-z-index: 0;*/

// Font Colors
$base-font-color: $grey;
$toastyfresh-action-color: $toastyfresh-purple;
$toastyfresh-button-color: $toastyfresh-green;

$link-color: #D97249;
$woocommerce-link-color: $toastyfresh-purple;
/*$link-color: $blue;*/

// Header
/*$header-background: $base-font-color;*/
/*$header-color: $silver;*/
/*$header-link-color: $header-color;*/
/*$header-link-hover: $blue;*/

// Footer
/*$footer-background: $header-background;
$footer-color: $header-color;
$footer-link-color: $header-link-color;
$footer-link-hover: $header-link-hover;*/

// Border
/*$base-border-color: $grey;*/
$base-border-color: lighten($grey, 30%);
$base-border: 1px solid $base-border-color;
/*$table-border: 1px solid lighten($grey, 30%);*/
$table-border: $base-border;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);

// Forms
$form-box-shadow: inset 0 1px 3px rgba($base-font-color, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($base-font-color, $lightness: -5%, $alpha: -0.3);

// Animations
//$base-duration: 150ms;
$base-duration: 1000ms;
$base-timing: ease;

$header-height: 169px;
$mobile-header-height: 4rem;
$mobile-header-element-height: $mobile-header-height / 1.8;

// Breakpoints

$large-mobile-screen-size: 480px;
$medium-screen-size: 600px;
$large-screen-size: 990px;
$x-large-screen-size: 1200px;
$navigation-breakpoint: 800px;

$large-mobile-screen: (
columns: 12,
gutter: 20px,
media: $large-mobile-screen-size,
);

$medium-screen: (
columns: 12,
gutter: 20px,
media: $medium-screen-size,
);

$large-screen: (
columns: 12,
gutter: 20px,
media: $large-screen-size,
);

$x-large-screen: (
columns: 12,
gutter: 20px,
media: $x-large-screen-size,
);

$navigation-breakpoint-screen: (
columns: 12,
gutter: 20px,
media: $navigation-breakpoint,
)