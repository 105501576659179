
.wpcf7-form invalid {


}

.wpcf7-not-valid-tip {
    color: $toastyproducts-red !important;
}

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
    border-color: $toastyproducts-red !important;
}

div.wpcf7-mail-sent-ok {
    border-color: $toastyfresh-green !important;
}