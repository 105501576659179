.banner {
    /*Mobile*/

    /*Large screen*/
    @include grid-media($large-screen) {
        position: relative;
        height: 313px;
    }

    .banner-text {

        /*Large screen*/
        @include grid-media($large-screen) {
            /*position: relative;*/
            position: absolute;
            width: 236px;
            top: 0;
            /*left: 753px;*/
            right: 0;
            padding: 13px 20px !important;            
        }

        .background {
            /*Mobile*/
            width: 100%; 
            height: 100%;
            background: $toastyfresh-purple;

            /*Large screen*/
            @include grid-media($large-screen) {
                position: absolute;
                top: 0;
                left: 0;
                filter:alpha(opacity=80); /* IE */
                -moz-opacity:0.8; /* Mozilla */
                opacity: 0.8; /* CSS3 */
                z-index: 1 !important;
            }
        }

        .text {
            /*Mobile*/
            margin-bottom: 1rem;
            line-height: 1.4em;
            font-size: 1.2em;

            /*Large screen*/
            @include grid-media($large-screen) {
                color: #fff;
                position: relative;
                z-index: 10;
                margin-bottom: 0;
            }

            .quote {
                font-style: italic;
                font-size: 1rem;
                line-height: 1.4rem;
            }

            .author {
                font-weight: bold;
            }

            .workplace {
                font-weight: bold;
            }
        }
    }

    img {
        /*Mobile*/
        position: static;
        max-width: 100%;

        /*Large screen*/
        @include grid-media($large-screen) {
            position: absolute;
        }
    }

}





.banner.go-green .banner-text {
    left: 0;

    .background {
        background: transparent;
    }
}

article.main-content {

    /* Large screen */
    @include grid-media($large-screen) {
        padding-top: 40px;
    }

    p.intro {
        font-size: 1.2em;
        color: #008E89;
        font-weight: bold;
    }

    > aside {
        /* Mobile */
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin-top: 2rem;

        /* Medium screen */
        @include grid-media($medium-screen) {
            width: 29%;
            margin-top: 0.5rem;
        }

        /* Large screen */
        @include grid-media($large-screen) {
            margin-left: 35px;
        }

        &.employee-quote {
            /*Mobile*/

            /* Medium screen */

            /* Large screen */
            @include grid-media($large-screen) {
                width: 235px;
                margin-bottom: 1rem;
                margin-left: 0px;
            }

            img {
                width: 184px;
                height: auto;
                float: right;
            }

            .speech-bubble {
                width: 173px;
                height: 232px;
                background: transparent url('../images/about-speech-bubble.png') top left no-repeat;
                position: absolute;
                top: 0;
                right: 15px;
                padding: 10px 15px 0 50px;

                p {
                    font-style: italic;
                    color: $white;
                    line-height: 1.5em;
                }
            }
        }

        &.employee-pic {

            /* Large screen */
            @include grid-media($large-screen) {
                width: 184px;

                img {
                    width: 184px;
                    height: auto;
                }
            }
        }
    }

    > .main-article {
        /*Mobile*/

        /* Medium screen */
        @include grid-media($medium-screen) {
            display: inline-block;
            vertical-align: top;
            width: 70%;
        }

        /*Large screen*/
        @include grid-media($large-screen) {
            width: 753px;
            margin-right: -0.25em;
        }
    }


}



