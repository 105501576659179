.page-template-toastyproducts_express {
    ul:not(.menu) {
        list-style: disc inside;
        width: 100%;
        padding-bottom: 1em;

        li {
            padding-top: 1em;
            font-size: 0.9rem;
        }
    }

    .toastyfresh-express-main-content {
        .main-text-block {
            /*margin-top: 40px;*/
        }

        .secondary-text-block {
            margin-top: 40px;
        }

        /* Medium screen */
        @include grid-media($medium-screen) {
            display: inline-block;
            width: 70%;
            .main-text-block {
                padding-right: 2rem;
            }
        }

        /*Large screen*/
        @include grid-media($large-screen) {
            display: inline-block;
            vertical-align: top;
            width: 707px;
            padding-right: 1rem;
            .main-text-block {
                padding-right: 0;
            }
        }
    }

    aside.toastyfresh-express-pic {
        margin-top: 2rem;

        /* Medium screen */
        @include grid-media($medium-screen) {
            display: inline-block;
            width: 29%;
            padding-top: 4rem;
            margin-top: 0;
            vertical-align: top;
        }

        /* Large screen */
        @include grid-media($large-screen) {
            /*margin-top: 40px;*/
            width: 277px;
            display: inline-block;
        }

        img {
            width: 100%;
        }
    }
}
