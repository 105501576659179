.homepage-infographic {
    display: inline-block;
    vertical-align: top;
    width: 710px;
    height: 529px;
    margin-left: 36px;
    margin-right: -0.25em;

    h1 {
        text-algin: center;
        font-size: 32pt;
        position: relative;
        top: 100px;        
    }
}