#{$all-buttons}, .button {
    appearance: none;
    background-color: $button-color;
    border: 0;
    border-radius: $base-border-radius !important;
    color: $white !important;
    cursor: pointer;
    display: inline-block;
    font-family: $base-font-family;
    font-size: $base-font-size * 2;
    -webkit-font-smoothing: antialiased;
    font-weight: 600;
    line-height: 1.2;
    /*padding: $small-spacing $base-spacing;*/
    text-decoration: none;
    /*transition: background-color $base-duration $base-timing;*/
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    /*width: 100%;*/
    text-align: center;
    padding: .618em 1em;

    /*Woocommerce buttons*/
    &.wc-forward, &.wc-backward, &[name='apply_coupon'], &[name='update_cart'], &[name='clear_cart'], &[name='login'], &[name='register']{
        background-color: $woocommerce-button-color !important;

        &:hover {
            background-color: shade($woocommerce-button-color, 20%) !important;
        }
    }

    &.checkout-button {
        background-color: $woocommerce-action-button-color !important;

        &:hover {
            background-color: shade($woocommerce-action-button-color, 20%) !important;
        }
    }

    &:hover, &:focus {
        color: #fff;

        background-color: $button-color !important;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}







.toasty-buttons {
    margin-top: 22px;

    ul {

        li {
            display: inline-block;
            vertical-align: middle;
        }
    }

    a.toasty-button {
        display: table-cell;
        width: 138px;
        height: 41px;
        vertical-align: middle;
        padding: 0 16px;
        text-align: center;
        font-size: 0.9em;
        font-weight: bold;
        line-height: 1.05em;
        color: #fff;

        &.orange {
            background: url('../images/orange-button.png') top left no-repeat;
        }

        &.brown {
            background: url('../images/brown-button.png') top left no-repeat;
        }

        &.green {
            background: url('../images/green-button.png') top left no-repeat;
        }
    }
}
