.main-content {

    &.go-green {

        aside {
            /*Mobile*/
            width: 100%;
            margin-bottom: 1rem;
            text-align: center;
            margin-top: 2rem;
            padding-right: 0;

            /*Large screen*/
            @include grid-media($large-screen) {
                width: 300px;
                margin-bottom: 0;
                text-align: left;
                margin-left: 50px;
                margin-top: 0.5rem;
                margin-bottom: 0;
            }

            img {
                /*Mobile*/
                width: 100%;

                /*Medium screen*/
                @include grid-media($medium-screen) {
                    display: inline-block;
                    width: auto;
                    margin: auto;
                }
            }
        }

        article.main-article {
            /*Mobile*/
            width: 100%;

            /*Medium screen*/
            @include grid-media($medium-screen) {
                display: inline-block;
                width: auto;
                /*margin-right: -0.25em;*/
            }

            /*Large screen*/
            @include grid-media($large-screen) {
                width: 638px;
            }
        }
    }
}