.homepage-welcome-message {
    vertical-align: top;
    display: inline-block;
    width: 240px;
    margin-top: 50px;

    img {
        max-width: 100%;
        height: auto;        
    }
}

.mobile-order-now-pic {
    /*Mobile*/
    
    /*Large screen*/
    @include grid-media($large-screen) {
        display: none;
    }
}

.order-now-pic {
    /*Mobile*/
    display: none;
    
    /*Large screen*/
    @include grid-media($large-screen) {
        display: block;
        margin-top: 2rem;
    }
}


