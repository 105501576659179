header {
    position: fixed;
    overflow: hidden;
    width: 90%;
    z-index: 99;
    background: #fff;
    height: $mobile-header-height;
    // border: 1px solid;
    display: flex;
    align-items: center;

    /* Large screen */
    @include grid-media($large-screen) {
        position: fixed;
        height: $header-height;
        overflow: hidden;
        width: 990px;
        display: block;
    }

    .top-nav {
        /*Mobile*/
        display: none;
        // border: 1px solid black;

        /*Large screen*/
        @include grid-media($large-screen) {
            display: block;
            width: 100%;
            height: 25px;
        }

        .menu {
            /*Mobile*/

            /*Large screen*/
            @include grid-media($large-screen) {
                float: right;
            }

            li {
                /*Mobile*/
                display: block;

                /*Large screen*/
                @include grid-media($large-screen) {
                    display: inline-block;
                    vertical-align: top;
                    text-align: center;
                    color: $white;
                }

                a {
                    /*Mobile*/
                    display: block;
                    color: $white;
                    background: $toastyfresh-purple;

                    /*Large screen*/
                    @include grid-media($large-screen) {
                        vertical-align: top;
                        display: inline-block;
                        width: 80px;
                        height: 25px;
                        line-height: 22px;
                        font-size: 1.1em;

                        -webkit-border-bottom-right-radius: $base-border-radius;
                        -webkit-border-bottom-left-radius: $base-border-radius;
                        -moz-border-radius-bottomright: $base-border-radius;
                        -moz-border-radius-bottomleft: $base-border-radius;
                        border-bottom-right-radius: $base-border-radius;
                        border-bottom-left-radius: $base-border-radius;
                        vertical-align: middle;
                    }

                    &:hover {
                        background-color: shade($toastyfresh-purple, 20%);
                    }
                }
            }
        }

        .logged-in-as {
            float: left;
        }
    }

    .product-nav {
        /*Mobile*/
        display: none;

        /*        &.show {
                    display: block;
                }*/

        /*Large screen*/
        @include grid-media($large-screen) {
            display: block !important;
            width: 100%;
            margin-top: 10px;
        }

        ul {
            overflow: auto;
            overflow: hidden;

            li {
                /*Mobile*/
                &.noshow {
                    display: none;
                }

                /*Large screen*/
                @include grid-media($large-screen) {
                    float: left;
                    margin-left: 3px;

                    &:first-child {
                        margin-left: 0;
                    }
                }

                a {
                    /*Mobile*/
                    // font-family: 'Istok Web';
                    // font-weight: bold;
                    display: block;
                    /*background: #939598;*/
                    background: $toastyfresh-tertiary;
                    color: $white;
                    padding: 1rem;
                    font-size: 1rem;

                    /*Large screen*/
                    @include grid-media($large-screen) {
                        display: table-cell;
                        vertical-align: middle;
                        width: 96px;
                        height: 43px;
                        font-size: 0.9em;
                        line-height: 1em;
                        padding: 3px;
                        text-align: center;
                        -webkit-border-top-left-radius: 5px;
                        -webkit-border-top-right-radius: 5px;
                        -moz-border-radius-topleft: 5px;
                        -moz-border-radius-topright: 5px;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                    }

                    &:hover {
                        color: $white !important;
                    }
                }

                &.current-menu-item {
                    a {
                        background: #f78f1e;
                    }
                }

                &.first-menu-item {
                    margin-left: 0;
                }
            }
        }
    }

    .contact {
        /*Mobile*/
        // display: none;
        .social-media-icons {
            $icon-size: 1.7rem;
            width: 50%;
            margin: auto;
            display: flex;
            justify-content: space-between;

            a.social-icon {
                color: $toastyfresh-tertiary !important;
                width: $icon-size;
                height: $icon-size;

                img.linkedin-social-icon {
                    filter: invert(64%) sepia(17%) saturate(6636%) hue-rotate(331deg) brightness(92%) contrast(83%);
                }

                span,
                img.linkedin-social-icon {
                    font-size: $icon-size;
                    width: 100%;
                    height: auto;
                }
            }
        }

        .phone {
            /* Mobile */
            color: $toastyfresh-green;
            font-weight: bold;
            text-align: center;
            display: none;

            span {
                &.number {
                    font-size: 1.33em;
                    font-weight: normal;
                }
            }

            /* Large screen */
            @include grid-media($large-screen) {
                float: left;
                clear: both;
                text-align: left;
                display: block;
            }
        }

        /*Large screen*/
        @include grid-media($large-screen) {
            display: inline-block;
            float: right;
            position: relative;
            top: 68px;
            // border: 5px solid black;

            p {
                padding-bottom: 0;
            }

            ul {
                float: right;

                li {
                    float: left;
                    margin-left: 10px;
                }
            }

            .social-media-icons {
                width: 100%;
            }
        }
    }

    img.header-logo {
        /*Mobile*/
        max-width: 300px;
        height: auto;
        width: auto;
        margin: auto;
        display: none;
        margin-top: 1rem;

        /*Medium screen*/
        @include grid-media($medium-screen) {
        }

        /*Large screen*/
        @include grid-media($large-screen) {
            display: inline-block;
            margin: 0;
            height: 66px;
            // width: auto;
            max-width: none;
            vertical-align: bottom;
            margin-left: 0;
            top: 0;
            margin-top: 23px;

            &.toastyfresh {
                float: right;
                margin-top: 6px;
                margin-right: 20px;
            }
        }




        /*Mobile*/
        // width: 350px;
        // margin-top: 1rem;
        // display: block;
        // margin: auto;
        // height: auto;

        // /*Medium screen*/
        // @include grid-media($medium-screen) {

        //     &.toastyfresh {
        //         float: right;
        //         margin-top: 6px;
        //         margin-right: 20px;
        //     }
        // }

        // /*Large screen*/
        // @include grid-media($large-screen) {
        //     display: inline-block;
        //     margin-top: 0;
        // }
    }

    .toasty-tagline {
        /*Mobile*/
        display: block;
        font-size: 1.4rem;
        color: $toastyproducts-green;
        margin-top: 1rem;
        margin-bottom: 1rem;
        text-align: center;

        /*Medium screen*/
        @include grid-media($medium-screen) {
            text-align: left;
        }

        /*Large screen*/
        @include grid-media($large-screen) {
            display: none;
            /*margin-left: 1.5rem;*/
            position: relative;
            top: -2px;
        }

        .tagline {
            /*Mobile*/
            display: block;
            margin-top: 1rem;
            text-align: center;

            /* Large screen */
            @include grid-media($large-screen) {
                margin-top: 0;
            }
        }
    }

    .union-jack {
        /*Mobile*/
        height: auto;
        width: 50px;
        margin: auto;
        margin-left: 1.3rem;

        /*Medium screen*/
        @include grid-media($medium-screen) {
            /*display: inline-block;*/
        }

        /*Large screen*/
        @include grid-media($large-screen) {
            display: inline-block;
            position: absolute;
            // top: -60px;
            height: 30px !important;
            margin-left: 5px;
            margin-top: 29px;
        }
    }

    .hat {
        margin-left: 1.3rem;
        height: 2.2rem;

        @include grid-media($large-screen) {
            display: none;
        }
    }
}
