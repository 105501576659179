footer {
    /*Mobile*/
    width: 100%;
    background: $toastyfresh-purple;
    font-size: 0.9rem;
    clear: both;

    p {
        font-size: 0.73rem;
    }

    /*Large screen*/
    @include grid-media($large-screen) {
        font-size: 0.8em;
    }

    .footer-content {
        /*Mobile*/
        width: 90%;

        /*Large screen*/
        @include grid-media($large-screen) {
            width: 990px;
        }
        padding: 10px 0 100px 0;
        margin: auto;

        .contact-details {
            /*Mobile*/
            color: #fff;
            margin-top: 2rem;

            /*Large screen*/
            @include grid-media($large-screen) {
                // float: left;
                margin-top: 0;
            }

            p {
                /*Mobile*/
                line-height: 1.8em;
                
                /*Large screen*/
                @include grid-media($large-screen) {
                    // line-height: 1.4em;
                }
            }
        }

        .copyright {
            /*Mobile*/
            color: #fff;

            /*Large screen*/
            @include grid-media($large-screen) {
                float: right;
                padding-left: 0.7em;
                font-size: 0.7rem;
                position: relative;
                top: -1px;
            }
        }

        .footer-nav {
            /*Mobile*/
            margin-top: 1rem;
            
            /*Large screen*/
            @include grid-media($large-screen) {
                margin-top: 0;
                float: right;
            }

            ul {
                /*Mobile*/

                li {
                    /*Mobile*/
                    display: block;
                    height: $mobile-nav-line-height;

                    /*Large screen*/
                    @include grid-media($large-screen) {
                        display: inline-block;
                        border-right: 1px solid;
                        height: auto;
                        line-height: 0.8em;
                        color: #D97249;
                        padding: 0 0.3rem;
                    }

                    a {
                        /*Mobile*/
                        display: block;
                        color: #D97249;
                        
                        /*Large screen*/
                        @include grid-media($large-screen) {
                            display: inline-block;
                            font-size: 0.75rem;
                        }
                    }

                    &:first-child {
                        border-left: none;
                        padding-left: 0;
                    }


                }
            }
        }
    }
}


