.product-order-form {

    .product-orders {
        /*Mobile*/

        /*Large screen*/
        @include grid-media($large-screen) {

            float: left;
            clear: both;
            overflow: auto;
            overflow: hidden;

            background: #f9ede1; /* Old browsers */
            background: -moz-linear-gradient(top,  #f9ede1 0%, #f8b87b 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f9ede1), color-stop(100%,#f8b87b)); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(top,  #f9ede1 0%,#f8b87b 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(top,  #f9ede1 0%,#f8b87b 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(top,  #f9ede1 0%,#f8b87b 100%); /* IE10+ */
            background: linear-gradient(to bottom,  #f9ede1 0%,#f8b87b 100%); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9ede1', endColorstr='#f8b87b',GradientType=0 ); /* IE6-9 */


            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            border-radius: 50px;

            padding-top: 15px;
            /*padding-right: 105px;*/
            padding-bottom: 15px;
            /*padding-left: 105px;*/
            padding-left: 50px;
            padding-right: 50px;

            overflow: auto;
            overflow: hidden;

            width: 900px;
            margin-top: 30px;
        }

        table {
            /*mobile*/
            width: 100%;

            /*Large screen*/

            text-align: left;

            thead {
                /*Mobile*/
                display: none;

                /*Large screen*/
                @include grid-media($large-screen) {
                    display: table-header-group;
                    font-weight: bold;
                }

                tr {
                    /*Mobile*/
                    display: block;

                    /*Large screen*/
                    @include grid-media($large-screen) {
                        display: table-row;
                    }

                    th {
                        @include grid-media($large-screen) {
                            padding: 5px 5px;
                        }
                    }
                }
            }

            tbody {

                tr {
                    /*Mobile*/
                    display: block;

                    /*Large screen*/
                    @include grid-media($large-screen) {
                        display: table-row;
                    }

                    td {
                        /*Mobile*/
                        display: block;

                        /*Large screen*/
                        @include grid-media($large-screen) {
                            display: table-cell;
                            padding: 2px 5px;
                            vertical-align: middle;
                        }

                        &.product-title {
                            display: none;

                            @include grid-media($large-screen) {
                                display: table-cell;
                            }

                            &.mobile {
                                display: block;

                                @include grid-media($large-screen) {
                                    display: none;
                                }
                            }

                            h2 {
                                /*Mobile*/
                                margin-bottom: 0.5rem;
                                /*font-weight: normal;*/

                                /*Large screen*/
                                @include grid-media($large-screen) {
                                    margin-bottom: 0;
                                    font-size: 0.9rem;
                                }
                            }
                        }

                        &.product-price {
                            /*Mobile*/
                            /*width: 50%;*/
                            /*background: yellow;*/
                            /*float: left;*/
                            font-weight: bold;

                            /*Large screen*/
                            @include grid-media($large-screen) {
                                /*width: auto;*/
                            }
                        }

                        &.product-code {
                            display: none;
                            font-weight: bold;

                            @include grid-media($large-screen) {
                                display: table-cell;
                            }

                            &.mobile {
                                display: block;

                                @include grid-media($large-screen) {
                                    display: none;
                                }
                            }
                        }
                        
                        &.product-weight {
                            font-weight: bold;
                        }

                        &.product-quantity {
                            /*Mobile*/
                            /*width: 50%;*/
                            /*background: red;*/
                            /*float: left;*/
                            font-weight: bold;

                            /*Large screen*/
                            @include grid-media($large-screen) {
                                /*width: auto;*/
                            }
                        }
                    }
                }
            }
        }
    }

    h2 {

        &.subcategory {
            /*Mobile*/
            margin-top: 30px;
            margin-bottom: 1rem;
            font-weight: bold;
            font-size: 1.4em;
            /*padding-bottom: 5px;*/
            color: $toastyfresh-purple;

            /*Large screen*/
            @include grid-media($large-screen) {
                float: left;
                margin-bottom: 0;
            }
        }
    }

    ul {

        li {
            float: left;
            margin-top: 10px;

            h2 {
                color: #5F6062;
                font-size: 1.4em;
                width: 550px;
                float: left;
            }

            div {

                &.quantity {
                    float: right;
                }
            }
        }
    }





    input[type=number], input[type=text] {
        /*float: right;*/
        background: #F59133;
        /*width: auto !important;*/
        border: none !important;

        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        border-radius: 0 !important;

        -webkit-box-shadow: none !important;
        -moz-box-shadow:    none !important;
        box-shadow:         none !important;

        /*margin-right: 30px !important;*/
        height: 30px !important;

        padding: 0;
        padding-left: 1em;
    }

    input[type=number] {
        /*Mobile*/
        margin-bottom: 1rem;
        display: inline-block;
        width: 45%;
        margin-right: 0 !important;

        /*Large screen*/
        @include grid-media($large-screen) {
            /*width: 5rem;*/
            width: auto;
            margin-bottom: 0;
        }
    }

    .total-boxes {
        padding: 30px 0 !important;

        label {
            /*Mobile*/
            color: $toastyfresh-purple;
            font-size: 2em !important;
            margin-bottom: 1rem;

            /*Large screen*/
            @include grid-media($large-screen) {
                text-align: right !important;
                font-weight: normal !important;
                width: 500px;
                /*float: left;*/
                float: right;
                margin-bottom: 0;
                margin-right: 60px;
            }
        }

        input[type=text] {
            /*Mobile*/

            /*Large screen*/
            @include grid-media($large-screen) {
                float: left;
                margin-left: 50px;
                /*width: 158px !important;*/
                margin-right: 0;
            }
        }
    }

    label {

        &.hidden-label {
            /*Mobile*/
            display: inline-block;
            width: 50%;

            /*Large screen*/
            @include grid-media($large-screen) {
                display: none;
            }
        }
    }

    .submit-button-holder {
        float: left;
        clear: both;
        width: 100%;
        text-align: center;
        padding-bottom: 100px;
    }

    input[type=submit] {
        clear: both;
        margin: auto;
        background: transparent url('../images/submit.jpg') top center no-repeat;
        width: 244px;
        height: 63px;
        line-height: 31px;
        border: none;
        cursor: pointer;
        margin-top: 20px;
        border-radius: 63px !important;
        font-size: 1.7rem;
        /*font-weight: normal;*/

        /*background: #f8b87b !important;  Old browsers */
        /*        background: -moz-linear-gradient(top,  #f9ede1 0%, #f8b87b 100%);  FF3.6+ 
                background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f9ede1), color-stop(100%,#f8b87b));  Chrome,Safari4+ 
                background: -webkit-linear-gradient(top,  #f9ede1 0%,#f8b87b 100%);  Chrome10+,Safari5.1+ 
                background: -o-linear-gradient(top,  #f9ede1 0%,#f8b87b 100%);  Opera 11.10+ 
                background: -ms-linear-gradient(top,  #f9ede1 0%,#f8b87b 100%);  IE10+ 
                background: linear-gradient(to bottom,  #f9ede1 0%,#f8b87b 100%);  W3C 
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9ede1', endColorstr='#f8b87b',GradientType=0 );  IE6-9 */

        color: $toastyfresh-purple !important;
    }
}