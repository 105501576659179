.cart-collaterals {
    .cart_totals {

        h2 {
            margin-bottom: 1rem;
        }
    }
}

.qty {
    padding: 0;
    width: 9rem !important;
    border: 0 !important;
}

.woocommerce table.shop_table {
    border: $table-border !important;
    border-top: none !important;
    
    tr.cart-total-cases {
        
        td {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
    }
    
    td, th {
        border-top: $table-border !important;
    }
}